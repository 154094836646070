// import { Img } from "globalStyles"
import { observer } from "mobx-react-lite"
import loanStore from "mobx/LoanStore"
import React, { useContext, useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { addFilesUnder, addFilesUnderReapply } from "services/LoanService"
// import { FileFormImg } from "utils/assets"
import { BASE_URL } from "services"
import http from "services/httpService"
import useSWR from "swr"
import UploadFileContainer from "./UploadContainer"
import useLoanDetail from "../LoanReview/useLoanDetail"
import { UploadsLoader } from "components/FullScreenLoader"
import { UndergraduateLoanStepContext } from "context/LoanStepContext"

const MyCheckbox =({checked,handleCheck})=>{
	if(checked){
		return(
			<button onClick={handleCheck} className="bg-secondary-600 rounded-full h-5 w-5 text-white flex items-center justify-center">
				<i className="bi bi-check text-xl"></i>
			</button>
		)
	}
	return (
		<button onClick={handleCheck} className="border-2 border-solid border-secondary-800 rounded-full h-5 w-5 text-white flex items-center justify-center"></button>
	)
}

const UndergradutateUploadForm = ({ mutate, id }) => {
	const {loading:loadingLoans,loan} = useLoanDetail()
	const {next,prev} = useContext(UndergraduateLoanStepContext)
	const [isReApplicant,setIsReapplicant] = useState(false)
	const [loading,setLoading] = useState(false)

	const [admissionFile, setadmissionFile] = useState(null)
	const [payEvidenceFile, setpayEvidenceFile] = useState(null)
	const [letterIdFile, setletterIdFile] = useState(null)
	const [schoolIdFile, setSchoolIdFile] = useState(null)

	const [admissionFileURL, setadmissionFileURL] = useState(null)
	const [payEvidenceFileURL, setpayEvidenceFileURL] = useState(null)
	const [letterIdFileURL, setletterIdFileURL] = useState(null)
	const [schoolIdFileURL, setSchoolIdFileURL] = useState(null)

	
	// const fileTypes = ["JPG", "PNG", "JPEG"]

	// const handleTypeError = () => {
	// 	toast.error("Upload image types with extension .jpeg, png, jpg")
	// }
	// const handleSizeError = file => {
	// 	toast.error("Maximum file size allowed is 50kb")
	// }

	const url = `${BASE_URL}/loans/user`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}

	const { data: loanAll } = useSWR(url, fetcher)

	const uploadDocuments = async () => {
		if (!admissionFile) return toast.error("Please upload your addmission letter")
		if (!letterIdFile) return toast.error("Please upload your letter of identification")
		// if (!schoolIdFile) return toast.error("Please upload your School Id card")

		const formData = new FormData()
		if(schoolIdFile){
			formData.append("school_identity_card", schoolIdFile[0])
		}
		formData.append("signed_letter_id", letterIdFile[0])
		formData.append("admission_letter", admissionFile[0])
		formData.set("id", id)
		setLoading(true)
		const response = await addFilesUnder(formData)
		setLoading(false)

		if (!response.error) {
			mutate()
			next()
		}
	}

	const uploadDocumentsReapply = async () => {
		if (!admissionFile) return toast.error("Please upload your addmission letter")
		if (!letterIdFile) return toast.error("Please upload your letter of identification")
		// if (!schoolIdFile) return toast.error("Please upload your School Id card")

		const formDatas = new FormData()
		if(schoolIdFile){
			formDatas.append("school_identity_card", schoolIdFile[0])
		}
		if(payEvidenceFile){
			formDatas.append("school_fee_receipt", payEvidenceFile[0])
		}
		formDatas.append("signed_letter_id", letterIdFile[0])
		formDatas.append("admission_letter", admissionFile[0])

		formDatas.set("id", id)

		setLoading(true)
		const response = await addFilesUnderReapply(formDatas)
		setLoading(false)

		if (!response.error) {
			mutate()
			next()
		}
	}

	useEffect(() => {
		if(loan){
			const existingLoans = loanAll.filter(l=>l.id !== loan.id && loan.status === "disbursed")
			console.log("existing", existingLoans)
			if(existingLoans.length < 1){
				setIsReapplicant(false)
			}else{
				setIsReapplicant(true)
			}
		}
	},[loanAll,loan])

	async function updateFile(fileURL,setFile){
		const data = await fetch(fileURL)
		const fileBlob = await data.blob()
		// console.log(fileBlob)
		setFile([fileBlob])
	}

	useEffect(() => {
		if(!loadingLoans && loan){
			console.log({loan})
			if(loan.admission_letter){
				setadmissionFileURL(loan.admission_letter)
				updateFile(loan.admission_letter,setadmissionFile)
			}
			if(loan.institution.school_identity_card){
				setSchoolIdFileURL(loan.institution.school_identity_card)
				updateFile(loan.institution.school_identity_card,setSchoolIdFile)
			}
			if(loan.school_fee_receipt){
				setpayEvidenceFileURL(loan.school_fee_receipt)
				updateFile(loan.school_fee_receipt,setpayEvidenceFile)
			}
			if(loan.signed_letter_id){
				setletterIdFileURL(loan.signed_letter_id)
				updateFile(loan.signed_letter_id,setletterIdFile)
			}
		}
	},[loan,loadingLoans])

	const handleToggle = ()=>{
		const existingLoans = loanAll.filter(l=>l.id !== loan.id && loan.status === "disbursed")
		if(existingLoans.length < 1){
				setIsReapplicant(prev => !prev)
		}
	}

	if(loadingLoans){
		return <UploadsLoader/>
	}
	
	return (
		<section>
		<div className="p-4">
			<h3 className="text-2xl text-center font-semibold text-secondary-800">Undergraduate Application Verification</h3>
			<div>
				<div className="">
					<div className="my-4 max-w-xl mx-auto">
						<UploadFileContainer
						formTitle={"Upload Admission Letter"}
						file={admissionFile}
						setFile={setadmissionFile}
						fileURL={admissionFileURL}
						setFileURL={setadmissionFileURL}
						/>
					</div>
					<div className="my-4 max-w-xl mx-auto">
						<UploadFileContainer
							note={"From Registrar, Dean of Student Affairs or Faculty Dean (duly stamped and signed)"} 
							formTitle={"Signed Letter of Identification"}
							file={letterIdFile}
							fileURL={letterIdFileURL}
							setFile={setletterIdFile}
							setFileURL={setletterIdFileURL}
						/>
					</div>
					<div className="my-4 max-w-xl mx-auto">
						<UploadFileContainer
						formTitle={"School Identity Card (Optional)"}
						file={schoolIdFile}
						fileURL={schoolIdFileURL}
						setFile={setSchoolIdFile}
						setFileURL={setSchoolIdFileURL}
						/>
					</div>
					<div className="my-4 max-w-xl mx-auto ">
					<div className="flex items-center gap-2 mt-16 mb-4 border-b border-solid py-4 border-gray-200">
						<p className="font-poppins">Are you a re-applicant?</p>
						<div className="flex items-center gap-2">
							<MyCheckbox handleCheck={handleToggle} checked={isReApplicant}/>
							<p className="font-poppins">Yes</p>
						</div>
						<div className="flex items-center gap-2">
							<MyCheckbox handleCheck={handleToggle} checked={!isReApplicant}/>
							<p className="font-poppins">No</p>
						</div>
					</div>
						{isReApplicant && (
							<UploadFileContainer
							formTitle={"Upload Previous Fee Payment Reciept"}
							file={payEvidenceFile}
							fileURL={payEvidenceFileURL}
							setFile={setpayEvidenceFile}
							setFileURL={setpayEvidenceFileURL}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
		<footer className="flex sticky bottom-0 bg-white py-4 p-4 border-solid items-center gap-2 justify-end border-t border-secondary-100">
			<button className="border border-solid border-primary-500 text-primary-900 rounded-full px-6 py-2 text-lg font-medium" onClick={prev} type="button">
				Back
			</button>
					<br />
			<button className="border border-solid border-primary-300 bg-primary-100 text-primary-900 rounded-full px-6 py-2 text-lg font-medium" disabled={loanStore.isLoading} onClick={!isReApplicant ? () => uploadDocuments() : () => uploadDocumentsReapply()} type="submit">
				{loading ? "Loading..." : "Proceed"}
			</button>
		</footer>
		</section>
	)
}

export default observer(UndergradutateUploadForm)
