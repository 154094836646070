import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { editFiles } from "services/LoanService"
import UploadFileContainer from "components/widgets/UploadContainer"
import {UploadsLoader} from "components/FullScreenLoader"
import useLoanDetail from "components/Dashboard/Apply/LoanReview/useLoanDetail"

const EditUploadForm = ({ mutate, id}) => {
	const {loading:loadingLoans,loan} = useLoanDetail()
	const [loading,setLoading] = useState(false)

	const [admissionFile, setadmissionFile] = useState(null)
	const [payEvidenceFile, setpayEvidenceFile] = useState(null)
	const [letterIdFile, setletterIdFile] = useState(null)
	const [schoolIdFile, setSchoolIdFile] = useState(null)
	const [previousFile, setPreviousFile] = useState(null)

	const [previousFileURL, setPreviousFileURL] = useState(null)
	const [admissionFileURL, setadmissionFileURL] = useState(null)
	const [payEvidenceFileURL, setpayEvidenceFileURL] = useState(null)
	const [letterIdFileURL, setletterIdFileURL] = useState(null)
	const [schoolIdFileURL, setSchoolIdFileURL] = useState(null)

	const uploadDocuments = async () => {
		if (!admissionFile) return toast.error("Please upload your addmission letter")
		if (!letterIdFile) return toast.error("Please upload your letter of identification")
		// if (!schoolIdFile) return toast.error("Please upload your School Id card")
		// if (!payEvidenceFile) return toast.error("Please upload your previous fee payment evidence")

		const formDatas = new FormData()
		if(schoolIdFile){
			formDatas.append("school_identity_card", schoolIdFile[0])
		}
		if(loan.loan_type === "postgraduate"){
			formDatas.append("othercertificate", previousFile[0])
		}
		if(payEvidenceFile){
			formDatas.append("school_fee_receipt", payEvidenceFile[0])
		}

		formDatas.append("signed_letter_id", letterIdFile[0])
		formDatas.append("admission_letter", admissionFile[0])

		formDatas.set("id", id)
		setLoading(true)
		const response = await editFiles(formDatas)
		setLoading(false)
		if (!response.error) {
			mutate()
		}
	}

	async function updateFile(fileURL,setFile){
		const data = await fetch(fileURL)
		const fileBlob = await data.blob()
		setFile([fileBlob])
	}

	useEffect(() => {
		if(!loadingLoans && loan){
			console.log({loan})
			if(loan.admission_letter){
				setadmissionFileURL(loan.admission_letter)
				updateFile(loan.admission_letter,setadmissionFile)
			}
			if(loan.institution.school_identity_card){
				setSchoolIdFileURL(loan.institution.school_identity_card)
				updateFile(loan.institution.school_identity_card,setSchoolIdFile)
			}
			if(loan.school_fee_receipt){
				setpayEvidenceFileURL(loan.school_fee_receipt)
				updateFile(loan.school_fee_receipt,setpayEvidenceFile)
			}
			if(loan.signed_letter_id){
				setletterIdFileURL(loan.signed_letter_id)
				updateFile(loan.signed_letter_id,setletterIdFile)
			}
			if(loan.previous_certificate){
				setPreviousFileURL(loan.previous_certificate)
				updateFile(loan.previous_certificate,setPreviousFile)
			}
		}
	},[loan,loadingLoans])

	if(loadingLoans){
		return <UploadsLoader/>
	}

	return (
		<section>
			<h3 className="text-2xl text-center mb-16 font-semibold text-secondary-800">Undergraduate Application Verification</h3>
			<div>
				<div className="p-4">
					<div className="my-4 max-w-xl mx-auto">
						<UploadFileContainer
						formTitle={"Upload Admission Letter"}
						file={admissionFile}
						setFile={setadmissionFile}
						fileURL={admissionFileURL}
						setFileURL={setadmissionFileURL}
						/>
					</div>
					<div className="my-4 max-w-xl mx-auto">
						<UploadFileContainer
							note={"From Registrar, Dean of Student Affairs or Faculty Dean (duly stamped and signed)"} 
							formTitle={"Signed Letter of Identification"}
							file={letterIdFile}
							fileURL={letterIdFileURL}
							setFile={setletterIdFile}
							setFileURL={setletterIdFileURL}
						/>
					</div>
					{loan.loan_type === "postgraduate" && (
						<div className="my-4 max-w-xl mx-auto">
						<UploadFileContainer
							formTitle={"Previous Certificate"}
							file={previousFile}
							fileURL={previousFileURL}
							setFile={setPreviousFile}
							setFileURL={setPreviousFileURL}
						/>
					</div>
					)}
					<div className="my-4 max-w-xl mx-auto">
						<UploadFileContainer
						formTitle={"School Identity Card (Optional)"}
						file={schoolIdFile}
						fileURL={schoolIdFileURL}
						setFile={setSchoolIdFile}
						setFileURL={setSchoolIdFileURL}
						/>
					</div>
					<div className="my-4 max-w-xl mx-auto">
					{payEvidenceFileURL && (
						<UploadFileContainer
						formTitle={"Upload Previous Fee Payment Reciept"}
						file={payEvidenceFile}
						fileURL={payEvidenceFileURL}
						setFile={setpayEvidenceFile}
						setFileURL={setpayEvidenceFileURL}
						/>
					)}
					</div>
				</div>
				<footer className="flex sticky bottom-0 p-4 bg-white py-4 border-solid items-center gap-2 justify-end my-4 border-t border-secondary-100">
					<button className="border border-solid border-primary-300 disabled:opacity-60 disabled:cursor-not-allowed bg-primary-100 text-primary-900 rounded-full px-6 py-2 text-lg font-medium" disabled={loading} onClick={uploadDocuments} type="submit">
						{loading ? "Loading..." : "Save"}
					</button>
				</footer>
			</div>
		</section>
	)
}

export default observer(EditUploadForm)
