import FullScreenLoader from 'components/FullScreenLoader'
import { closePaymentModal } from 'flutterwave-react-v3'
import { observer } from 'mobx-react-lite'
import authStore from 'mobx/AuthStore'
import loanStore from 'mobx/LoanStore'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  DASHBOARD_APPLY_ROUTE,
  // DASHBOARD_NEXTOFKINROUTE,
  // DASHBOARD_PAYMENT_DETAILS_ROUTE,
  // DASHBOARD_PROFILEROUTE,
} from 'routes'
import { BASE_URL } from 'services'
import http from 'services/httpService'
import { getCurrentLoanStage, verifyPayment } from 'services/LoanService'
import useSWR from 'swr'
import { CreditCardImg } from 'utils/assets'
import  { usePayment } from '../../../config'
// import { onBoardStep } from 'routes/apirRoutes'
// import { checkOnBoarding } from 'services/MiscService'
// import toast from 'react-hot-toast'
import { UndergraduateLoanStepContext } from 'context/LoanStepContext'
import { PostGradLoanStepContext } from 'context/PostLoanStepContext'
import { Spin } from 'antd'

const AppFee = ({loanType="undergraduate",id }) => {
  const postGradContext = useContext(PostGradLoanStepContext)
  const underGradContext = useContext(UndergraduateLoanStepContext)

  const getContext=()=>{
    if(loanType === "postgraduate"){
        return postGradContext
    }
    return underGradContext
  }

  const {prev} = getContext()

  const navigate = useNavigate()

  let segment;
  const fullUrl = window.location.href
  segment = fullUrl.split('/').pop()

  const url = `${BASE_URL}/users/who_i_am`
  const fetcher = async () => {
    authStore.loggedInStatus()
    const res = await http.get(url)
    return res?.data?.data
  }

  const { data: userProfile } = useSWR(url, fetcher)


  const {loading,setLoading,startPayment} = usePayment({
    userProfile,
    callback: async (response) => {
      setLoading(false)
      // console.log(response)
      if (response.status === 'successful' || response.status === 'completed' ) {
        const {tx_ref:reference,transaction_id} = response
        const payment = await verifyPayment({reference,id,transaction_id,})
        if(payment){ console.log(payment)}
        navigate(`${DASHBOARD_APPLY_ROUTE}`)
        return;
      }
      closePaymentModal()
       // this will close the modal programmatically
    },
  })

  // useEffect(() => {
  //   const handleOnBoarded = async () => {
  //     const info = await checkOnBoarding()
  //     if (info?.error) {
  //       toast.error(info.error)
  //     } else {
  //       // reroute to onboarding page
  //       if (!info.onBoarded && info.nextStep === onBoardStep.profile) {
  //         navigate(DASHBOARD_PROFILEROUTE)
  //       } else if (!info.onBoarded && info.nextStep === onBoardStep.next_of_kin) {
  //         navigate(DASHBOARD_NEXTOFKINROUTE)
  //       } else if (
  //         !info.onBoarded &&
  //         info.nextStep === onBoardStep.bank_account
  //       ) {
  //         navigate(DASHBOARD_PAYMENT_DETAILS_ROUTE)
  //       }
  //     }
  //   }
  //   handleOnBoarded()
  // }, [navigate])

  useEffect(() => {
    const currentLoanProgress = async () => {
      await getCurrentLoanStage({
        search: 'app_fee',
        loanId: segment,
      })
      // use data to as default value
    }
    currentLoanProgress()
  }, [segment])


  if (!userProfile) return <FullScreenLoader show={true} />
  if (loanStore.isLoading_pay) return <FullScreenLoader show={true} />
  return (
    <>
      <div>
          <h4 className="text-center text-4xl font-semibold text-secondary">Processing Fee</h4>
          <div className="bg-white py-8 p-4 rounded-lg max-w-xl mx-auto my-6">
              <div>
                <img className="max-w-[200px] mx-auto" src={CreditCardImg} alt="credit carrd" />
            </div>
            <div className="my-4">
              <p className="font-poppins">
                Kindly note that there is no application fee attached to the BYSHELB
                loan. However, you are required to pay a processing fee of
                ₦2,000.00. <span className="font-poppins font-semibold">Please make only CARD (VISA or Mastercard) payments on the nextscreen.</span>
              </p>
            </div>
          </div>
          <footer className="flex py-2 gap-2 px-4 pt-4 border-t border-gray-200 justify-end border-solid sticky bottom-0 bg-gray-50">
              <button onClick={prev} className="bg-secondary-50 border border-solid border-secondary-500 rounded-full py-2 text-secondary-500 disabled:opacity-60 disabled:cursor-not-allowed  px-6" type="button">
                  <span>Back</span>
              </button>
                <button onClick={startPayment} className="bg-secondary-50 border border-solid border-secondary-500 rounded-full py-2 text-secondary-500 disabled:opacity-60 disabled:cursor-not-allowed  px-6" type="button" disabled={loading}>
                  {
                    loading ? (
                      <Spin/>
                    ):(
                      <span>Pay Now</span>
                    )
                  }
                </button>
          </footer>

        {/* <ProceedBtn onClick={prev}>Back</ProceedBtn> */}
      </div>
	  </>
  )
}

export default observer(AppFee)
